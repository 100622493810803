<template>
    <div class=" components bgF2">
        <div class="head height-100 backgroundWhite ">
            <div class="head-logo ">
                <img :src="imgObj.homeImg" width="50px" height="50px">
                <div class="head-logo-name">
                    <div class="appName">旺街邮</div>
                    <div class="appData">发货找车的物流货运平台</div>
                </div>
            </div>

            <div class="theme">
                <div class="themeItem" v-for="item in themeList " :key="item.id">
                    <div @click="onButtonClick( item.type)"
                         class="buttonCursor marginRight-110 flex-column flexAlignItems ">
                        <div v-if="item.type===showTheme">
                            <div class="text16_c3">{{item.name}}</div>
                            <div class="headLin"></div>
                        </div>
                        <div v-else>
                            <div class="text16_33">{{item.name}}</div>
                            <div class="whiteheadLin "></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class=" main">
        <div class="flexAlignItems">
            <div class="main_pcData">
                <div class="pc_headline fontBold">{{newData.title}}</div>
                <div class="pc_newTime">{{newData.createTime}}</div>
                <div v-html="newData.content"></div>
            </div>

        </div>
        <div>
            <div class="buttonLin"></div>
            <div class="button">
                <div class="flex-column marginTop-40">
                    <img class="logoImg" :src="imgObj.logoMin">
                    <div class="companyName">杭州旺街网络科技有限公司</div>
                    <div class="companyAddress">Copyright © 2018 杭州旺街网络科技 版权所有</div>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18030766号-1</a>
                    <div class="head companyAddress">
                        <img class="badge" :src="imgObj.badge">
                        <a class="marginLeft-10" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002583" target="_blank">浙公网安备 33010902002583号</a>
                    </div>
                </div>
                <div class="buttonLin-height marginTop-40 marginLeft-80"></div>
                <div class="flex-column marginTop-40 marginLeft-80">
                    <div class="text14c33">电话：0571-82136687</div>
                    <div class="text14c33">客服微信：wonstreet</div>
                    <div class="text14c33">邮箱：wonstreet@126.com</div>
                    <div class="text14c33">地址：杭州市萧山区农业大厦1号楼25层</div>
                </div>

                <div class="head marginLeftAuto">
                    <div class="buttonLin-height marginTop-10 marginRight-50 "></div>
                    <div class="flex-column flexAlignItems marginRight-50">
                        <img class="codeSize" :src="imgObj.downloadCode">
                        <div class="text14c33">扫描下载旺街邮APP</div>
                    </div>
                    <div class="flex-column flexAlignItems ">
                        <img class="codeSize" :src="imgObj.publicCode">
                        <div class="text14c33 ">扫描关注旺街邮公众号</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import homeImg from '@/assets/img/logo.png'
    import logoMin from '@/views/img/logo-min.png'
    import badge from '@/views/img/badge.png'
    import downloadCode from '@/views/img/download_code.png'
    import publicCode from '@/views/img/public_code.png'
    import axios from "axios";

    export default {
        name: "informationData",
        data() {
            return {
                slide: 0,
                sliding: null,
                imgObj: {homeImg, logoMin, badge, publicCode, downloadCode},
                showTheme: "dynamic",
                HTTP: null,
                id: this.$route.params.id,
                pageNumber: 1,
                newData: {},
                themeList: [
                    {
                        type: "dynamic",
                        name: "最新动态",
                        id: 1,
                    },
                    {
                        type: "about",
                        name: "关于我们",
                        id: 2,
                    },
                    {
                        type: "shipments",
                        name: "老板发货",
                        id: 3,
                    },
                    {
                        type: "order",
                        name: "司机接单",
                        id: 4,
                    },
                    {
                        type: "relation",
                        name: "联系我们",
                        id: 5,
                    },
                ]
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            },
            onButtonClick: function (e) {
                this.showTheme = e;
                // document.getElementById(e).scrollIntoView();
                this.$router.push({
                    path: '/', query: {
                        type: this.showTheme,
                    }
                })
            },

            init() {
                let  newsId=this.id.replace('.html','')
                this.HTTP = axios.create({
                    baseURL: 'https://yourestpro.wonstreet.com',
                    timeout: 1000,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    params: {
                        id: newsId,
                    }
                });


                this.HTTP.post('/br/information/getDetail')
                    .then((res) => {
                        this.newData = res.data.result

                    })

            }
        },
        /**
         * 数据加载完成后
         */
        mounted() {

        },
        created() {
            this.init()

        },
    }
</script>
<style>
    html, body {
        height: 100%;
        margin: 0;
    }
    .components {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        /*布局方向是垂直的*/
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .head {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 12px 40px 0px
        rgba(229, 229, 229, 0.42);
        text-align: center;
    }

    .main {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        overflow: auto;
    }

    .height-100 {
        height: 100px
    }

    .theme {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: 100px;

    }

    .themeItem {
        display: flex;
        flex-direction: row;
        margin-left: auto;
    }

    .head-logo {
        display: flex;
        flex-direction: row;
        margin-left: 160px;
        align-items: center;
    }

    .head-logo-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }

    .appName {
        font-size: 24px;
        margin-left: 20px;
        color: #333333;
    }

    .appData {
        font-size: 18px;
        color: #666666;
        margin-left: 20px;
    }




    .buttonLin {
        width: auto;
        height: 4px;
        background-color: #00c395;
        margin-top: 100px;
    }

    .button {
        padding-top: 10px;
        padding-bottom: 60px;
        width: 1600px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .buttonCursor {
        cursor: pointer;
    }

    .flexAlignItems {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .marginLeftAuto {
        margin-left: auto;
        padding-top: 30px;


    }

    .logoImg {
        width: 50px;
        height: 50px;
    }

    .badge {
        width: 20px;
        height: 20px;
    }

    .companyName {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 18px;
        color: #333333;
    }

    .companyAddress {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: #999999;
    }

    .codeSize {
        width: 160px;
        height: 160px;
    }

    .marginTop-40 {
        margin-top: 40px;
    }

    .marginLeft-80 {
        margin-left: 80px;
    }

    .marginRight-50 {
        margin-right: 50px;

    }

    .marginRight-110 {
        margin-right: 110px;

    }

    .marginTop-10 {
        margin-top: 10px;
    }

    .buttonLin-height {
        width: 1px;
        height: 140px;
        background-color: #e8e8e8;
    }

    .text14c33 {
        font-size: 14px;
        margin-top: 10px;
        color: #333333;
        display: flex;
        flex-direction: row;
    }

    .text24c33 {
        margin-top: 10px;
        color: #333333;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        align-items: flex-start;
        justify-content: flex-start;


    }

    .headLin {
        width: 24px;
        height: 2px;
        background-color: #00c395;
        border-radius: 1px;
        margin-left: 18px;
        margin-top: 10px;
    }

    .whiteheadLin {
        width: 24px;
        height: 2px;
        background-color: #ffffff;
        border-radius: 1px;

        margin-top: 10px;
    }

    .text16_33 {
        font-size: 16px;
        color: #333333;
    }

    .text16_c3 {
        font-size: 16px;
        color: #00c395;
    }

    .dynamicData {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 1600px;
        background-color: #f8f9fb;
    }

    .dynamicItem {
        display: flex;
        flex-direction: row;
        background-color: #00c395;
        width: 800px;
    }

    .stable_img {
        margin-left: auto;
        width: 240px;
        height: 160px;
        background-color: darkblue;

    }

    .box1 {
        width: 1600px;
        background: #EEEEEE;
    }

    .box1 ul {
        clear: both;
        overflow: hidden;
    }

    .box1 ul li {
        width: 48%;
        height: 200px;
        margin-bottom: 10px;
        float: left;
        display: flex;
        flex-direction: row;
        justify-items: center;
    }

    .pc_headline{
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        margin-top: 30px;
        justify-content: flex-start;
        justify-items: flex-start;
        align-content: flex-start;
        color: #333333;
    }
    .pc_newTime{
        font-size:16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        margin-top: 30px;
        color: #999999;
    }
    .main_pcData{
        display: flex;
        flex-direction: column;
        width: 1600px;
    }
    .bgF2{
        background-color: #f2f2f2;
    }
    .backgroundWhite{
        background-color: white;
    }
    .headDiv {
        position: fixed;
        background-color: white;
        /*固定定位*/
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .fontBold{
        font-weight:bold
    }
</style>
